
import { computed, defineComponent, reactive, toRefs } from 'vue';

import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import modal from 'ant-design-vue/lib/modal';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import axios from '@/utils/axios';

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const { cookies } = useCookies();
    const router = useRouter();

    let formState = reactive({
      email: '',
      name: '',
      password: '',
      password2: '',
      code: '',
    });

    const signState = reactive({
      isSignUp: false,
      isLoadingEmail: false,
      isSendEmail: false,
      isLoadingCode: false,
      isCompleteAuth: false,
    });

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const authEmail = async () => {
      signState.isLoadingEmail = true;
      axios
        .post(`/auth/sign-up/email`, {
          email: formState.email,
        })
        .then((res) => {
          signState.isSendEmail = true;
        })
        .catch((err) => {
          if (err.response.data.message.statusCode === 422) {
            modal.error({
              title: 'Duplicate email',
              content: 'This email is already registered.',
            });
          }
        })
        .finally(() => {
          signState.isLoadingEmail = false;
        });
    };

    const authCode = async () => {
      signState.isLoadingCode = true;

      axios
        .post(`/auth/sign-up/verify`, {
          email: formState.email,
          code: formState.code,
        })
        .then((res) => {
          signState.isCompleteAuth = true;
          modal.info({
            title: 'Authentication success',
            content: 'Email authentication completed.',
          });
        })
        .catch((err) => {
          modal.error({
            title: 'Authentication failed',
            content: 'Please check the authentication number again.',
          });
        })
        .finally(() => {
          signState.isLoadingCode = false;
        });
    };

    const isValidEmail = computed(() => {
      const emailValidRegex = /^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}/;
      return !formState.email.match(emailValidRegex);
    });

    const submit = async () => {
      if (signState.isSignUp) {
        axios
          .post(`/auth/sign-up/`, {
            email: formState.email,
            name: formState.name,
            password: formState.password,
            passwordConfirm: formState.password,
          })
          .then(() => {
            Modal.info({
              title: 'Sign-up success',
              // content: '',
            });
            signState.isSignUp = false;
          })
          .catch((e) => {
            Modal.info({
              title: 'Sign-up failed',
              content: e.response.data.message.message,
            });
          });
      } else {
        axios
          .post(`/auth/login/`, {
            email: formState.email,
            password: formState.password,
          })
          .then(({ data }) => {
            cookies.set('previewerAccessToken', data.accessToken, '1d');
            router.push('/');
          })
          .catch((e) => {
            Modal.info({
              title: 'Sign-in failed',
              content: e.response.data.message.message,
            });
          });
      }
    };

    return {
      ...toRefs(signState),
      formState,
      layout,
      isValidEmail,
      authEmail,
      authCode,
      submit,
    };
  },
});
